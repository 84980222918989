<!-- NOW THIS PAGE WILL SHOW ALL THE TRAINERS-->

<template>
  <v-container fluid>
    <v-toolbar flat  class="grey lighten-4">
      <v-toolbar-title class="text-h6 font-weight-bold">New trainers to onboard</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <!-- <CreateTrainerDailog /> -->
    </v-toolbar>
    <v-data-table
      sort-by
      :headers="headers"
      :items="trainerList"
      :search="search"
      :loading="loading"
      class="elevation-1"
      :items-per-page="7"
    >
      <template v-slot:item.actions="{ item }">
                <v-icon small color="black" @click="editTrainer(item)">open_in_new</v-icon>
              </template>
      <template slot="no-data">
        <v-alert :value="true" color="secondary" icon="person">
          Start entering the trainers!
        </v-alert>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>

/* eslint-disable */
import { mapState } from 'vuex'
//import CreateTrainerDailog from '../../../components/CreateTrainerDailog/createTrainerDailog.vue'
export default {
  name: 'Trainers',
  components:{
  //  CreateTrainerDailog,
  },
  data: () => ({
    search: '',
    deleteDialogOpen: false,
    trainerFromTap:[],
    otOwners:["Brij" , "David","Kishore","Poonam" ,"Sujeet"],
    headers: [
      { text: 'Trainer Id', value: 'trainerId' },
      { text: 'Trainer Name', value: 'fullName' },
      { text: 'Source', value: 'sourceType'},
      { text: 'Journey Status', value: 'status' , sortable:false },
      { text: 'Account Filled Status', value: 'accountFilledStatus', sortable: false },
      { text: 'Created Date', value: 'creationDate' },
      { text: 'Ot Owner', value: 'otOwner' },
      { text: "Actions", value: "actions", sortable: false}
    ]
  }),
  computed: {
    ...mapState('trainerProfile', {
      trainer: 'trainer',
      trainerList: 'trainerList',
      loading: 'loading'
    }),
    formTitle () {
      return this.trainer.id === null ? 'New Trainer' : 'Edit Trainer'
    }
  },
  created () {
      console.log("trainer list from state --- listing page", this.trainerList)  
    Store.dispatch('trainerProfile/getTrainerList')
  
  },
  methods: {
    cb () {
      this.$refs.trainerForm.reset()
      this.dialogOpen = false
      this.deleteDialogOpen = false
    },
   editTrainer(trainer) {
      Store.dispatch('trainerProfile/getTrainerProfile', { ...trainer })
    },
  }
}
</script>
